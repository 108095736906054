const domain = (<any>window).env.deviceManagementDomain || 'dm.iomtdev.bbraun.io';
export const environment = {
  production: false,
  develop: true,
  environment: 'local',
  enableProdMode: false,
  gppUrl: 'https://myprofile.test.account.bbraun.com/',
  postLogoutRedirectUri: 'http://localhost:4200/',
  swdRemoteEntryUrl: `https://${domain}/swd/remoteEntry.json`,
  diRemoteEntryUrl: `https://${domain}/di/remoteEntry.json`,
  apis: {
    swd: `https://${domain}/api/swd/`,
    di: `https://${domain}/api/di/`
  },
  msalConfig: {
    auth: {
      clientId: 'ba592d16-6663-4e72-a6f9-c744c9005aaa'
    }
  },
  apiConfig: {
    scopes: ['openid', 'profile', 'offline_access'],
    resources: {
      swd: {
        scope: ['https://test.account.bbraun.com/6e18f536-fe29-42d3-8fce-e5f75c3d5ae8/Read'],
        api: `https://${domain}/api/swd/`
      },
      di: {
        scope: ['https://test.account.bbraun.com/33ac7dca-6021-4816-89f3-cd2b231f8b83/Read'],
        api: `https://${domain}/api/di/`
      },
      gpp: {
        scope: ['https://test.account.bbraun.com/auth/gpr/User.Read'],
        api: 'https://api.myprofile.test.account.bbraun.com/api/'
      }
    }
  },
  b2cPolicies: {
    names: {
      signUpSignIn: 'B2C_1A__SIGNUP_SIGNIN_MFA'
    },
    authorities: {
      signUpSignIn: {
        authority:
          'https://test.account.bbraun.com/dadf93c1-1d2f-487d-a595-5d5428a9ae96/B2C_1A__SIGNUP_SIGNIN_MFA/'
      }
    },
    authorityDomain: 'test.account.bbraun.com'
  }
};
